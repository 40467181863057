import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import EcnButton from "../StyledComponents/EcnButton";
import { loginFirm } from "../../helpers/TempData";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE || "";

const AccountSelection: React.FC<{ setStep: any }> = ({ setStep }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams?.get("account-for") || "";
  const origin = searchParams?.get("origin") || "";
  const error_type = searchParams?.get("error") || "";
  let url: URL | undefined;
  if (origin?.trim() !== "") {
    url = new URL(origin);
  }

  const [active, setActive] = useState("");

  // const confirmHandler = () => {
  //   if (active === "net") {
  //     setStep(1);
  //     navigate(`?account-for=networking&origin=${origin}`);
  //   } else {
  //     setStep(1);
  //     navigate(`?account-for=firm&origin=${origin}`);
  //   }
  // };
  const selectCardHandler = () => {
    setActive("net");
    setStep(1);
    navigate(`?account-for=networking&origin=${origin}`);
  };
  const firHandler = () => {
    window.location.href = loginFirm(GATEWAY_BASE) + "signup"
  };

  return (
    <div className="account-selection">
      <h1 className="title">Select Account You Want to Continue with</h1>
      <div className="select">
        <div
          className={
            view === "networking" ? "select-card active" : "select-card"
          }
          onClick={selectCardHandler}
        >
          <div className="images first-image">
            <img
              src="https://s1.ecnstatic.com/ecn/images/Woman having online meeting with colleagues.png"
              alt=""
            />
          </div>
          <div>
            <h2>Professionals & Students</h2>
            <p>
              Are you a student or professional looking to access latest events, jobs, trainings & more?
            </p>
          </div>
        </div>
        <div
          className={view === "firm" ? "select-card active" : "select-card"}
          onClick={firHandler}
        >
          <div className="images">
            <img
              src="https://s1.ecnstatic.com/ecn/images/CV for hiring a new employee.png"
              alt=""
            />
          </div>
          <div>
            <h2>Firms</h2>
            <p>
              Are you a firm looking to set up an enterprise account for talent,
              recruiting & more?
            </p>
          </div>


        </div>
      </div>

      {error_type != "" && (
        <p
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#ba0000",
            marginTop: "15px",
          }}
        >
          {error_type?.replace('-', ' ').replace(/^./, (str) => str.toUpperCase())}
        </p>
      )}
      {/* {view != "" && (
        <div className="button">
          <EcnButton variant="contained" onClick={confirmHandler}>
            {" "}
            Next{" "}
            <Icon
              icon="lucide:arrow-right"
              className="right-icon"
              width={19}
              height={19}
            />
          </EcnButton>
        </div>
      )} */}
    </div>
  );
};

export default AccountSelection;
