import { Icon } from "@iconify/react";
import { Button, IconButton, InputAdornment, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckPassword from "../components/SignUp/CheckPassword";
import EcnButton from "../components/StyledComponents/EcnButton";
import EcnInput from "../components/StyledComponents/EcnInput";
import layoutOne from "../layouts/layoutOne";
import OtpVerification from "../components/SignUp/OtpVerification";
import SuccessCard from "../components/SignUp/SuccessCard";
import { postSignUp } from "../redux/slices/userSlices/signUpSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { correctEmail } from "../helpers/Validations/BasicValidations";

import { useNavigate, useSearchParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {
  isValidOrigin,
  loginFirm,
  Redirect,
  RedirectForNetwork,
} from "../helpers/TempData";
import { userDetails } from "../redux/slices/userSlices/userDetailsSlice";
import AccountSelection from "../components/SignUp/AccountSelection";
import { userSocialLogin } from "../redux/slices/userSlices/userSocialLoginSlice";

const API_GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE || "";

const Signup = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams?.get("account-for") || "";
  const origin = searchParams?.get("origin") || "";
  const session_id = searchParams?.get("session_id") || "";
  const state_type = searchParams?.get("state") || "";
  const error_type = searchParams?.get("error") || "";
  let url: URL | undefined;
  if (origin?.trim() !== "") {
    url = new URL(origin);
  }

  const { postSignUpData, loading, errorData } = useAppSelector(
    (state) => state.signUpData
  );
  const { userInfo, error: userError } = useAppSelector(
    (state) => state.userDetails
  );

  const { login, success } = useAppSelector(
    (state) => state.userSocialLoginData
  );

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState<any>(0);
  const [click, setClick] = useState(false);
  const [isStrong, setIsStrong] = useState(0);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (postSignUpData && click) {
      setStep(2);
    }
  }, [postSignUpData]);

  useEffect(() => {
    dispatch(userDetails());
  }, []);

  useEffect(() => {
    if (session_id != "") {
      dispatch(userSocialLogin(session_id));
    }
  }, [session_id]);

  useEffect(() => {
    if (error_type != "") {
      setStep(1);
      navigate(
        `?error=${error_type}&account-for=${state_type === "b2b_signup" ? "firm" : "networking"
        }`
      );
    }
  }, [error_type]);

  useEffect(() => {
    if (success && state_type != "") {
      if (state_type === "b2c_signup") {
        window.location.href = RedirectForNetwork(API_GATEWAY_BASE);
      } else if (state_type === "b2b_signup") {
        window.location.href = Redirect(API_GATEWAY_BASE);
      } else {
        navigate("/dashboard");
      }
    }
  }, [success, state_type]);

  useEffect(() => {
    if (userInfo && origin)
      window.location.href = url?.origin + "/signup/details";
    else if (userInfo && !origin) {
      navigate("/dashboard");
    }
  }, [userInfo]);

  useEffect(() => {
    if (view === "") {
      setStep(0);
    }
  }, [view]);

  const confirmHandler = () => {
    let isValid = false;

    if (!correctEmail.test(email)) {
      isValid = true;
      setIsValidEmail(isValid);
    } else {
      setClick(true);
      setIsValidEmail(false);
      dispatch(
        postSignUp({
          email: email,
          password: password,
          enterprise: view === "firm" ? true : false,
        })
      );
    }
  };

  const emailHandler = (e: any) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    const domain = emailValue?.split("@")[1];

    const disallowedDomains = [".edu", ".ac"];

    const isUniversityEmail = disallowedDomains?.some((ext) =>
      domain?.endsWith(ext)
    );

    if (isUniversityEmail) {
      setError(true);
    } else {
      setError(null);
    }
  };

  const loginHandler = () => {
    navigate(`/signin?origin=${origin}`);
  };

  const switchAccountHandler = () => {
    // if (view === "networking") {
    //   navigate(`?account-for=firm&origin=${origin}`);
    // } else {
    //   navigate(`?account-for=networking&origin=${origin}`);
    // }
    window.location.href = loginFirm(API_GATEWAY_BASE) + "signup"
  };

  return (
    <div className="signup">
      {step === 0 && session_id === "" ? (
        <AccountSelection setStep={setStep} />
      ) : (
        step === 0 && (
          <CircularProgress className="loader" style={{ marginTop: "200px" }} />
        )
      )}

      {step === 1 && (
        <div className="card">
          <h1>
            {view === "networking"
              ? "Create User Account"
              : "Create Firm Account"}
          </h1>

          <div className="field">
            <label htmlFor="">Personal Email/Gmail</label>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "10px",
                opacity: "0.7",
                color: error ? "rgba(186, 0, 0, 1)" : "",
              }}
            >
              Please use Personal Email/Gmail, as that is preferred by Firms &
              Platforms. You can add{" "}
              {view === "networking" ? "university/firm" : "work"} email on the next
              page
            </p>
            <EcnInput
              placeholder="Enter email address"
              className={`ecn-input signin-input ${errorData?.message === "400" || error ? "error-state" : ""
                }`}
              value={email}
              onChange={emailHandler}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      icon="lucide:mail"
                      height={20}
                      width={20}
                      color="black"
                    />
                  </InputAdornment>
                ),
              }}
            />
            {isValidEmail && (
              <p style={{ color: "rgba(186, 0, 0, 1)", marginTop: "4px" }}>
                Invalid email address
              </p>
            )}
            {errorData?.message === "400" && (
              <p
                style={{
                  color: "rgba(186, 0, 0, 1)",
                  marginTop: "-2px",
                  marginBottom: "4px",
                }}
              >
                Account already exist
              </p>
            )}
          </div>

          <CheckPassword
            setPassword={setPassword}
            setConfirmPassword={setConfirmPassword}
            password={password}
            confirmPassword={confirmPassword}
            setIsStrong={setIsStrong}
          />

          {isStrong != 4 && (
            <p className="mobile-case">
              Must contain 1 uppercase,1 number, min 8 characters , special
              character
            </p>
          )}

          <div className="button">
            <EcnButton
              variant="contained"
              disabled={
                password === "" ||
                email === "" ||
                confirmPassword === "" ||
                confirmPassword !== password ||
                isStrong !== 4 ||
                loading ||
                error
              }
              onClick={confirmHandler}
            >
              {" "}
              Next{" "}
              {!loading && (
                <Icon
                  icon="lucide:arrow-right"
                  className="right-icon"
                  width={18}
                  height={18}
                />
              )}
              {loading && (
                <CircularProgress
                  sx={{ color: "white", marginLeft: "4px" }}
                  size={15}
                />
              )}
            </EcnButton>
          </div>

          <div className="signup-with-google-and-microsoft">
            <div className="con">
              <p>Or Continue with</p>
            </div>
            <div className="google-micro-button">
              <div className="google">

                <Button onClick={() => window.open(`https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=986683004214-4j74ama78t15qvqeu4pudr2020omh6be.apps.googleusercontent.com&scope=email%20profile&access_type=offline&prompt=consent&state=
${view === "firm" ? "b2b_signup" : "b2c_signup"
                  }&redirect_uri=${API_GATEWAY_BASE}/auth/v1/v1/google/signup
 `, "_self")}>
                  <img src="https://s1.ecnstatic.com/ecn/images/common/Google.png" />{" "}
                  Google
                </Button>

              </div>
              <div className="google">

                <Button onClick={() => window.open(`
https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=7dc3715c-6148-414e-a4a2-73da0f8d9419&response_type=code&redirect_uri=${API_GATEWAY_BASE}/auth/v1/v1/microsoft/signup&state=${view === "firm" ? "b2b_signup" : "b2c_signup"
                  }&response_mode=query&scope=user.read%20openid%20profile%20email`, "_self")}>
                  {" "}
                  <img src="https://s1.ecnstatic.com/ecn/images/common/Microsoft.png" />
                  Microsoft
                </Button>

              </div>
            </div>
            {error_type != "" && (
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#ba0000",
                  marginTop: "-15px",
                }}
              >
                {error_type?.replace('-', ' ').replace(/^./, (str) => str.toUpperCase())}
              </p>
            )}
          </div>

          <p className="login-text">
            {" "}
            Already have an account? <span onClick={loginHandler}>Log in</span>
          </p>

          <div className="switch-account">
            <p>
              Not{" "}
              {view === "networking"
                ? "student or professional"
                : "firm or company"}{" "}
              ?
            </p>
            <h1 onClick={switchAccountHandler}>
              Create Account for Firm
            </h1>
          </div>
        </div>
      )}

      {step === 2 && (
        <div>
          <OtpVerification
            setStep={setStep}
            email={email}
            password={password}
          />
        </div>
      )}

      {step === 3 && <SuccessCard />}
    </div>
  );
};

export default layoutOne(Signup);
