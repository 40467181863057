import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import layoutOne from "../layouts/layoutOne";
import EcnInput from "../components/StyledComponents/EcnInput";
import { CircularProgress, Dialog, InputAdornment } from "@mui/material";
import { Icon } from "@iconify/react";
import CheckPassword from "../components/SignUp/CheckPassword";
import EcnButton from "../components/StyledComponents/EcnButton";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { acceptInvite } from "../redux/slices/userSlices/acceptInviteSlice";
import { recruitRedirect } from "../helpers/utils";
import { userSignin } from "../redux/slices/userSlices/userSigninSlice";

const AcceptUser = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const orgName = searchParams.get("org_name");


  const { acceptInviteData, loading } = useAppSelector(
    (state: any) => state.acceptInvite
  );

  const [password, setPassword] = useState("");
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState({
    first_name: "",
    last_name: ""
  })

  useEffect(() => {
    if (acceptInviteData) {
      setOpen(true);
      dispatch(userSignin({ email, password, enterprise: true }));

    }
  }, [acceptInviteData]);


  useEffect(() => {
    generateRandomPassword(8); // Call the function with the desired password length
  }, []);


  const generateRandomPassword = (length: any) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const specialCharacters = '!@#$%^&*()_+[]{}|;:,.<>?';

    const allCharacters = characters + specialCharacters;
    let password = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * allCharacters.length);
      password += allCharacters[randomIndex];
    }

    setPassword(password);
  }

  const confirmHandler = () => {
    if (password === "") {
      return;
    } else {
      dispatch(
        acceptInvite({
          token,
          password: password,
          confirm_password: password,
          first_name: data.first_name,
          last_name: data.last_name,

        })
      );
    }
  };

  const inputHandler = (e: any, type: string) => {
    setData({
      ...data,
      [type]: e.target.value
    })

  }

  const handleClose = () => {
    window.location.href = `${recruitRedirect()}dashboard`;
  };

  return (
    <div className="signup accepted-user">
      <div className="container">
        {/* <div className="branding">
          <div className="old">
            <img className="old-logo" src="/logo.png" alt="" />
            <p className="sub-head">is now part of</p>
          </div>
          <div className="new">
            <img className="ecn-logo" src="/one_logo.png" alt="Elevate" />
            <p className="sub">One Financial Network</p>
          </div>
        </div> */}
        <div className="card accept-flow">
          <h1>Create Talent Account</h1>


          <div className="row">
            <div className="name-field ">
              <div className='field'>
                <label htmlFor="">First Name*</label>
                <EcnInput
                  placeholder="Enter First name  "
                  className={`ecn-input signin-input`}
                  onChange={(e) => inputHandler(e, "first_name")}
                />
              </div>
              <div className='field'>
                <label htmlFor="">Last Name*</label>
                <EcnInput
                  placeholder="Enter Last name  "
                  className={`ecn-input signin-input`}
                  onChange={(e) => inputHandler(e, "last_name")}
                />
              </div>
            </div>
          </div>
          <div className="field">
            <label htmlFor="">Email*</label>
            <EcnInput
              placeholder="Enter email address"
              className="ecn-input signin-input"
              value={email}
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon icon="lucide:mail" height={20} width={20} color="grey" />
                  </InputAdornment>
                ),
              }}
            />
            {/* <p className="email-verified">
              <Icon icon="charm:tick" /> Verified
            </p> */}
          </div>

          <div className='field'>
            <label htmlFor="">Organisation Name*</label>
            <EcnInput
              placeholder="Enter Last name  "
              className={`ecn-input signin-input`}
              value={orgName}
              disabled
            />
          </div>

          <div className="button">
            <EcnButton
              variant="contained"
              disabled={
                data?.first_name === "" ||
                data?.last_name === "" ||
                loading  // Add loading condition here if needed

              }
              onClick={confirmHandler}
            >
              Create{" "}
              {loading && (
                <CircularProgress
                  sx={{ color: "white", marginLeft: "10px" }}
                  size={15}
                />
              )}
            </EcnButton>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-success"
      >
        <div className="img">
          <img src="https://ecn.blob.core.windows.net/ecn/images/Animation - 1733162269661.gif" alt="" />
        </div>
        <div className="text">
          <h4>Your account has been created successfully</h4>
          <p>Your password has been sent to your email. Please note that your functionality is limited as you are currently on the <span>Free Plan.</span></p>

        </div>
      </Dialog>

    </div>
  );
};

export default layoutOne(AcceptUser);
